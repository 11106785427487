<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner">
<!--      <div class="user-info-admin__caption">-->
<!--        Created-->
<!--      </div>-->
<!--      <div class="user-info-admin__large">-->
<!--        21 Mar, 2021-->
<!--      </div>-->
<!--      <div class="user-info-admin__history">-->
<!--        <div class="site-link text-decoration-underline mt-2 btn-style brown"-->
<!--             @click="changeHistoryOfChanges(true)"-->
<!--        >-->
<!--          Check History of Changes-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="user-info-admin__status in-progress">-->
<!--        In Progress-->
<!--      </div>-->
<!--      <div class="user-info-admin__bio">-->
<!--        <div class="user-info-admin__photo">-->
<!--          <img src="/img/trash-group/admin-user-info-img.png" alt="photo" width="32" height="32">-->
<!--        </div>-->
<!--        <div class="user-info-admin__name">-->
<!--          Anna Volkova-->
<!--        </div>-->
<!--      </div>-->

      <div class="user-info-admin__lock-system-btn"
           v-if="!isSubAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['siteSetting_LockSystemForUser', 'siteSetting_UnblockForUser'])"></span>
        <MainButton
          class="lock-system-btn"
          v-if="!lockForUser"
          @click.native="changeLockForUser(true)"
          :value="$t('siteSetting_LockSystemForUser.localization_value.value')"
        />
        <MainButton
            class="lock-system-btn"
            v-else
            @click.native="changeSystemLockForUsers(false)"
            :value="$t('siteSetting_UnblockForUser.localization_value.value')"
        />
      </div>
      <div class="user-info-admin__lock-system-btn"
           v-if="checkVisibleBtn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['siteSetting_blockCompletely', 'siteSetting_unblockSystem'])"></span>
        <MainButton
            class="lock-system-btn"
            v-if="!completeSystemBlock"
            @click.native="changeBlockCompletely(true)"
            :value="$t('siteSetting_blockCompletely.localization_value.value')"
        />
        <MainButton
            class="lock-system-btn"
            v-else
            @click.native="unlockSystem"
            :value="$t('siteSetting_unblockSystem.localization_value.value')"
        />
      </div>
    </div>


<!--    <HistoryOfChanges-->
<!--        v-if="isModalHistoryOfChanges"-->
<!--        @close="changeHistoryOfChanges(false)"-->
<!--    />-->
    <LockForUser
        v-if="isModalLockForUser"
        @close="changeLockForUser(false)"
        @confirm="changeSystemLockForUsers(true)"
    />

    <BlockCompletely
        v-if="isModalBlockCompletely"
        @close="changeBlockCompletely(false)"
        @confirm="blockSystemCompletely"
    />
  </div>
</template>

<script>
  // import HistoryOfChanges from "../../../../../popups/HistoryOfChanges/HistoryOfChanges";
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import LockForUser from "../../../../../popups/LockForUser/LockForUser";
  import BlockCompletely from "../../../../../popups/BlockCompletely/BlockCompletely";
  import {globalHelperMixin} from "../../../../../../../../mixins/globalMixins/globalHelperMixin";
  import {SYSTEM_ROLES} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "UserInfo",
    components: {
      BlockCompletely,
      LockForUser,
      // HistoryOfChanges,
      MainButton,
    },

    mixins: [
        globalHelperMixin
    ],

    watch: {
      loadUserRoleAndAuthorizedData() {
        this.checkCanLockSite()
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if(!this.isSubAdmin){
          this.checkCanLockSite()
        }
      }

      this.completeSystemBlock = !!localStorage.getItem('maintenance_mode_flag')
    },

    computed: {
      checkVisibleBtn() {
        if (this.getUserRole?.id === SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.id || this.getUserRole?.id === SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.id) {
          return true
        }

        return false
      }
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
        isModalLockForUser: false,
        isModalBlockCompletely: false,

        lockForUser: false,
        completeSystemBlock: false,
      }
    },

    methods:{
      checkCanLockSite() {
        //30 - system_locked_for_users setting id
        let id = 30
        this.$store.dispatch('getSetting', id).then(response => {
          let respData = this.getRespData(response)
          this.lockForUser = respData.value === '1'
        })
      },

      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },

      changeLockForUser(val){
        this.isModalLockForUser= val
      },
      changeBlockCompletely(val){
        this.isModalBlockCompletely= val
      },

      changeSystemLockForUsers(lock) {

        let settingValue = '0'

        if (lock) {
          settingValue = '1'
        }

        let data = {
          'id' : 30,
          'data' : {
            'name' : 'system_locked_for_users',
            'value' : settingValue,
            'type_id': '2'
          }
        }

        this.$store.dispatch('updateLockSystemForUsers', data).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')

              let respData = this.getRespData(response)
              this.lockForUser = respData.value === '1'

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

          this.changeLockForUser(false)
        })
      },

      blockSystemCompletely() {
        this.$store.dispatch('blockSystem', {}).then(response => {

          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')
              this.completeSystemBlock = true
              localStorage.setItem('maintenance_mode_flag', '1')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
        this.changeBlockCompletely(false)
      },

      unlockSystem() {
        this.$store.dispatch('unblockSystem', {}).then(response => {

          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')
              this.completeSystemBlock = false
              localStorage.removeItem('maintenance_mode_flag')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
        this.changeBlockCompletely(false)
      },
    }
  }
</script>

<style scoped>

</style>
