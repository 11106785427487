<template>
  <AccordBlock>
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_balanceUnderZero',])"></div>
        {{$t('siteSetting_balanceUnderZero.localization_value.value')}}
      </div>
    </template>

    <template slot="body">

      <div class="custom-row pt-3 pb-2">
        <div class="custom-col mb-0">
          <div class="balance-under-row"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'siteSetting_turnOnNotificationsNBOne',
                'siteSetting_turnOnNotificationsNBTwo',
                'siteSetting_days',
                ])"></div>
            {{$t('siteSetting_turnOnNotificationsNBOne.localization_value.value')}}
            <InputSum
                class="balance-under-row__input"
                :icoType="'dollar'"
                v-model="balance.data.negativeBalance"
            />
            {{$t('siteSetting_turnOnNotificationsNBTwo.localization_value.value')}}
            <DefaultInput
                class="center balance-under-row__input"
                v-model="balance.data.negativeBalanceDays"
            />
            {{$t('siteSetting_days.localization_value.value')}}
          </div>
        </div>
        <div class="custom-col mb-0">
          <div class="balance-under-row"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'siteSetting_turnOffFuncNBOne',
                'siteSetting_turnOffFuncNBTwo',
                'siteSetting_days',
                ])"></div>
            {{$t('siteSetting_turnOffFuncNBOne.localization_value.value')}}
            <InputSum
                class="balance-under-row__input"
                :icoType="'dollar'"
                v-model="balance.data.negativeBalanceBlock"
            />
            {{$t('siteSetting_turnOffFuncNBTwo.localization_value.value')}}
            <DefaultInput
                class="center balance-under-row__input"
                v-model="balance.data.negativeBalanceBlockDays"
            />
            {{$t('siteSetting_days.localization_value.value')}}
          </div>
        </div>
      </div>



    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "BalanceUnder",
    components: {
      AccordBlock,
      InputSum,
      DefaultInput,
    },

    mixins: [mixinDetictingMobile],

    props: {
      balance: Object,
    },

    data() {
      return {
        options: [{}],

        checkox01: true,

        input01: '-10',
        input02: '5',
        input03: '-100',
        input04: '7',
      }
    },
  }
</script>

<style scoped lang="scss">
  .balance-under-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__input{
      margin: 8px 10px;
      max-width: 138px;

      @media(max-width: 1600px){
        max-width: 105px;
        margin: 4px 10px;
      }

      &.center{
        max-width: 104px;

        @media(max-width: 1600px){
          max-width: 50px;
        }
      }
    }
  }
</style>
