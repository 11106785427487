import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {ShippingCompanies} from "./ShippingCompanies";
import {Limits} from "./Limits";
import {Balance} from "./Balance";
import {General} from "./General";
import {Engraving} from "./Engraving";

export function MainSettings() {

  this.checkValid = checkValid

  this.data = {
    ShippingCompanies: new ShippingCompanies(),
    Limits: new Limits(),
    Balance: new Balance(),
    General: new General(),
    Engraving: new Engraving(),

    historyOfChanged: null,
  }

  // this.validation = {
  //   shippingDate: false,
  // }
  //
  // this.validationTranslate = {
  //   shippingDate: '',
  // }
  //
  // this.validationTxt = {
  //   shippingDate: false,
  // }

}


/*****************   SETTERS   ******************/

MainSettings.prototype.setShippingCompanies = function (val) {
  this.data.ShippingCompanies.setShippingCompaniesItem(val)
}

MainSettings.prototype.setLimitsItem = function (val) {
  this.data.Limits.setLimitsItem(val)
}

MainSettings.prototype.setBalanceItem = function (val) {
  this.data.Balance.setBalanceItem(val)
}

MainSettings.prototype.setGeneralItem = function (val) {
  this.data.General.setGeneralItem(val)
}

MainSettings.prototype.setEngravingItem = function (val) {
  this.data.Engraving.setEngravingItem(val)
}

MainSettings.prototype.setHistoryOfChanged = function (val) {
  this.data.historyOfChanged = val
}



/*****************   GETTERS   ******************/



/*******************   FUNCTIONS   *************/


/******************  VALIDATIONS  *****************/




/******************  PREPARE DATA  *****************/


MainSettings.prototype.prepareData = function () {
  let data,
    prepareShippingCompanies = this.data.ShippingCompanies.prepareData(),
    prepareLimits = this.data.Limits.prepareData(),
    prepareBalance = this.data.Balance.prepareData(),
    prepareGeneral = this.data.General.prepareData(),
    prepareEngraving = this.data.Engraving.prepareData()

  data = {
    "output_kit_group_minimum_limit" : prepareLimits.outputKitGroupMinimumLimit,
    "output_ua_card_day_limit" : prepareLimits.outputUaCardDayLimit,
    "output_ua_card_week_limit" : prepareLimits.outputUaCardWeekLimit,
    "output_ua_card_month_limit" : prepareLimits.outputUaCardMonthLimit,

    "output_ua_commission_charge_500" : prepareLimits.outputCommission500,
    "output_ua_commission_charge_1000" : prepareLimits.outputCommission1000,
    "output_ua_commission_charge_3000" : prepareLimits.outputCommission3000,

    "percent_dhl_ua" : prepareShippingCompanies.ukrainian,
    "percent_dhl_us" : prepareShippingCompanies.usa,
    "percent_dhl_by" : prepareShippingCompanies.belarus,

    "percent_cdek_default" : prepareShippingCompanies.CDEKShippingPrice,
    // "percent_cdek_vip" : prepareShippingCompanies.belarus,

    "percent_tnt_default" : prepareShippingCompanies.TNTPercentDefault,
    "percent_tnt_vip" : prepareShippingCompanies.TNTPercentVip,

    "percent_fedex_default" : prepareShippingCompanies.FedexPercentDefault,
    "percent_fedex_russia" : prepareShippingCompanies.FedexRussiaPercentDefault,
    "percent_fedex_baltic_plus" : prepareShippingCompanies.FedexBalticPercentDefault,
    "percent_fedex_poland" : prepareShippingCompanies.FedexPolandPercentDefault,
    "percent_fedex_gb" : prepareShippingCompanies.FedexGBPercentDefault,

    "percent_npg_default" : prepareShippingCompanies.NovaPoshtaGlobalPercentDefault,

    "user_negative_balance_max_days" : prepareBalance.negativeBalanceDays,
    "user_negative_balance_min_limit" : prepareBalance.negativeBalance,
    "max_days_with_negative_balance" : prepareBalance.negativeBalanceBlockDays,
    "negative_balance_limit_for_restriction" : prepareBalance.negativeBalanceBlock,

    "percent_order_outbound_insurance_cost" : prepareGeneral.orderOutboundInsuranceCost,
    "order_fee_per_item_over_three" : prepareGeneral.orderFeePerItemOverThree,
    "eu_consolidation_vat" : prepareGeneral.euConsolidationVat,
    "product_consolidation_vat" : prepareGeneral.productConsolidationVat,
    "ireland_express_vat" : prepareGeneral.irelandExpressVat,

    "one_hour_engraving_cost" : prepareEngraving.oneHourEngravingCost,
    "engraving_tariff_per_area" : Number(prepareEngraving.engravingTariffPerArea),
    "engraving_tariff" : Number(prepareEngraving.engravingTariff),
    "engraving_tariff_different" : Number(prepareEngraving.engravingTariffDifferent),
    "engraving_adjusting_the_layout" : Number(prepareEngraving.engravingAdjustingTheLayout),
    "engraving_tariff_per_order" : Number(prepareEngraving.engravingTariffPerOrder),
    "engraving_discount" : Number(prepareEngraving.engravingDiscount),
  }

  return data
}
