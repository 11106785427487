<template>
  <AccordBlock>
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_Limits',])"></div>
        {{$t('siteSetting_Limits.localization_value.value')}}
      </div>
    </template>

    <template slot="body">


      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_RestrictionsSum',])"></div>
        {{$t('siteSetting_RestrictionsSum.localization_value.value')}}

      </div>
      <div class="custom-row pt-3">
        <div class="custom-col custom-col--25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_RestrictionsSumMinimumLimit',])"></div>
          <InputSum
                  :icoType="'dollar'"
                  :label="$t('siteSetting_RestrictionsSumMinimumLimit.localization_value.value')"
                  :value="setPriceValue(limits.data.outputKitGroupMinimumLimit)"
                  @change="(val) => {limits.data.outputKitGroupMinimumLimit = val}"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_RestrictionsSumPerDay',])"></div>
          <InputSum
                  :icoType="'dollar'"
                  :label="$t('siteSetting_RestrictionsSumPerDay.localization_value.value')"
                  :value="setPriceValue(limits.data.outputUaCardDayLimit)"
                  @change="(val) => {limits.data.outputUaCardDayLimit = val}"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_RestrictionsSumPerWeak',])"></div>
          <InputSum
                  :label="$t('siteSetting_RestrictionsSumPerWeek.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(limits.data.outputUaCardWeekLimit)"
                  @change="(val) => {limits.data.outputUaCardWeekLimit = val}"
          />
        </div>
        <div class="custom-col custom-col--25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_RestrictionsSumPerMonth',])"></div>
          <InputSum
                  :label="$t('siteSetting_RestrictionsSumPerMonth.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(limits.data.outputUaCardMonthLimit)"
                  @change="(val) => {limits.data.outputUaCardMonthLimit = val}"
          />
        </div>
      </div>

      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_OutputCommission',])"></div>
        {{$t('siteSetting_OutputCommission.localization_value.value')}}

      </div>
      <div class="custom-row pt-3">
        <div class="custom-col custom-col--33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_OutputCommission500',])"></div>
          <InputSum
                  :label="$t('siteSetting_OutputCommission500.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(limits.data.outputCommission500)"
                  @change="(val) => {limits.data.outputCommission500 = val}"
          />
        </div>
        <div class="custom-col custom-col--33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_OutputCommission1000',])"></div>
          <InputSum
                  :label="$t('siteSetting_OutputCommission1000.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(limits.data.outputCommission1000)"
                  @change="(val) => {limits.data.outputCommission1000 = val}"
          />
        </div>
        <div class="custom-col custom-col--33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_OutputCommission3000',])"></div>
          <InputSum
                  :label="$t('siteSetting_OutputCommission3000.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(limits.data.outputCommission3000)"
                  @change="(val) => {limits.data.outputCommission3000 = val}"
          />
        </div>
      </div>

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";

  export default {
    name: "Limits",
    components: {
      AccordBlock,
      InputSum,
    },

    mixins: [mixinDetictingMobile],

    props: {
      limits: Object,
    },

    data() {
      return {

      }
    },
  }
</script>

<style scoped lang="scss">

</style>
