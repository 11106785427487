import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import _ from "lodash";
import {
  PERCENT_ORDER_OUTBOUND_INSURANCE_COST,
  ORDER_FEE_PER_ITEM_OVER_THREE,
  EU_CONSOLIDATION_VAT,
  PRODUCT_CONSOLIDATION_VAT,
  IRELAND_EXPRESS_VAT,
} from "../../../../../staticData/staticVariables";

export function General() {
  this.checkValid = checkValid

  this.data = {
    skladUSAFee: '0',
    payPalReferrals: '0',
    warehouseReferrals: '0',
    defaultInterestRate: '0',
    bonusTotalTransactions: '0',

    referralSpentFBM: '0',
    refereeReceiveBalance: '0',

    periodShowInfoToolPanel: null,

    insuranceCost: '0',
    orderFeePerItemOverThree: '0',
    euConsolidationVat: '0',
    productConsolidationVat: '0',
    irelandExpressVat: '0',
  }

  this.validation = {
    skladUSAFee: false,
    payPalReferrals: false,
    warehouseReferrals: false,
    defaultInterestRate: false,
    bonusTotalTransactions: false,
    referralSpentFBM: false,
    refereeReceiveBalance: false,
    periodShowInfoToolPanel: false,
    insuranceCost: false,
    orderFeePerItemOverThree: false,
    euConsolidationVat: false,
    productConsolidationVat: false,
    irelandExpressVat: false,
  }

  this.validationTranslate = {
    skladUSAFee: '',
    payPalReferrals: '',
    warehouseReferrals: '',
    defaultInterestRate: '',
    bonusTotalTransactions: '',
    referralSpentFBM: '',
    refereeReceiveBalance: '',
    periodShowInfoToolPanel: '',
    insuranceCost: '',
    orderFeePerItemOverThree: '',
    euConsolidationVat: '',
    productConsolidationVat: '',
    irelandExpressVat: '',
  }

  /**
   * Getters
   */
  this.getSkladUSAFee = () => {
    return this.data.skladUSAFee
  }
  this.getPayPalReferrals = () => {
    return this.data.payPalReferrals
  }
  this.getWarehouseReferrals = () => {
    return this.data.warehouseReferrals
  }
  this.getDefaultInterestRate = () => {
    return this.data.defaultInterestRate
  }
  this.getBonusTotalTransactions = () => {
    return this.data.bonusTotalTransactions
  }
  this.getReferralSpentFBM = () => {
    return this.data.referralSpentFBM
  }
  this.getRefereeReceiveBalance = () => {
    return this.data.refereeReceiveBalance
  }
  this.getPeriodShowInfoToolPanel = () => {
    return this.data.periodShowInfoToolPanel
  }
  this.getInsuranceCost = () => {
    return this.data.insuranceCost
  }
  this.getOrderFeePerItemOverThree = () => {
    return this.data.orderFeePerItemOverThree
  }
  this.getEuConsolidationVat = () => {
    return this.data.euConsolidationVat
  }
  this.getProductConsolidationVat = () => {
    return this.data.productConsolidationVat
  }
  this.getIrelandExpressVat = () => {
    return this.data.irelandExpressVat
  }
  /**
   * Setters
   */
  this.setSkladUSAFee = (val) => {
    this.data.skladUSAFee = val
  }
  this.setPayPalReferrals = (val) => {
    this.data.payPalReferrals = val
  }
  this.setWarehouseReferrals = (val) => {
    this.data.warehouseReferrals = val
  }
  this.setDefaultInterestRate = (val) => {
    this.data.defaultInterestRate = val
  }
  this.setBonusTotalTransactions = (val) => {
    this.data.bonusTotalTransactions = val
  }
  this.setReferralSpentFBM = (val) => {
    this.data.referralSpentFBM = val
  }
  this.setRefereeReceiveBalance = (val) => {
    this.data.refereeReceiveBalance = val
  }
  this.setPeriodShowInfoToolPanel = (val) => {
    this.data.periodShowInfoToolPanel = val
  }
  this.setInsuranceCost = (val) => {
    this.data.insuranceCost = val
  }
  this.setOrderFeePerItemOverThree = (val) => {
    this.data.orderFeePerItemOverThree = val
  }
  this.setEuConsolidationVat = (val) => {
    this.data.euConsolidationVat = val
  }
  this.setProductConsolidationVat = (val) => {
    this.data.productConsolidationVat = val
  }
  this.setIrelandExpressVat = (val) => {
    this.data.irelandExpressVat = val
  }
}




/******************  Setter DATA  *****************/
General.prototype.setGeneralItem = function (val) {
  this.setInsuranceCost(parseFloat(_.find(val, {name: PERCENT_ORDER_OUTBOUND_INSURANCE_COST})?.value).toFixed(2))
  this.setOrderFeePerItemOverThree(parseFloat(_.find(val, {name: ORDER_FEE_PER_ITEM_OVER_THREE})?.value).toFixed(2))
  this.setEuConsolidationVat(parseFloat(_.find(val, {name: EU_CONSOLIDATION_VAT})?.value).toFixed(2))
  this.setProductConsolidationVat(parseFloat(_.find(val, {name: PRODUCT_CONSOLIDATION_VAT})?.value).toFixed(2))
  this.setIrelandExpressVat(parseFloat(_.find(val, {name: IRELAND_EXPRESS_VAT})?.value).toFixed(2))
}


/******************  PREPARE DATA  *****************/

General.prototype.prepareData = function () {

  return {
    "orderOutboundInsuranceCost": this.getInsuranceCost(),
    "orderFeePerItemOverThree": this.getOrderFeePerItemOverThree(),
    "euConsolidationVat": this.getEuConsolidationVat(),
    "productConsolidationVat": this.getProductConsolidationVat(),
    "irelandExpressVat": this.getIrelandExpressVat(),
  }
}
