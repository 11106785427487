<template>
  <AccordBlock>
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_generalSysPrefs'])"></div>
        {{$t('siteSetting_generalSysPrefs.localization_value.value')}}
      </div>
    </template>

    <template slot="body">

      <!--<div class="custom-row pt-3">-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
          <!--<InputSum-->
              <!--:label="'SkladUSA fee'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.skladUSAFee"-->
          <!--/>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
          <!--<InputSum-->
              <!--:label="'PayPal Referals'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.payPalReferrals"-->
          <!--/>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
          <!--<InputSum-->
              <!--:label="'Warehouse Referals'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.warehouseReferrals"-->
          <!--/>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
          <!--<InputSum-->
              <!--:label="'Default interest rate'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.defaultInterestRate"-->
          <!--/>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
          <!--<InputSum-->
              <!--:label="'Bonus % from the total transactions'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.bonusTotalTransactions"-->
          <!--/>-->
        <!--</div>-->
      <!--</div>-->

      <!--<div class="section-label">-->
        <!--FBM Bonuses-->
      <!--</div>-->

      <!--<div class="custom-row pt-2">-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50 pt-2">-->
          <!--<InputSum-->
              <!--class="label-wrap"-->
              <!--:label="'Referral spent to pay for FBM invoices'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.referralSpentFBM"-->
          <!--/>-->
        <!--</div>-->
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50 pt-2">-->
          <!--<InputSum-->
              <!--class="label-wrap"-->
              <!--:label="'The referee will receive the balance'"-->
              <!--:icoType="'dollar'"-->
              <!--v-model="general.data.refereeReceiveBalance"-->
          <!--/>-->
        <!--</div>-->
      <!--</div>-->

      <!--<div class="section-label">-->
        <!--Details-->
      <!--</div>-->

      <!--<div class="custom-row">-->
        <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
          <!--<DefaultSelect-->
              <!--:options="options"-->
              <!--:label="'period to show info on Tools pannel'"-->
          <!--/>-->
        <!--</div>-->
      <!--</div>-->

      <div class="custom-row mt-3">
        <div class="custom-col">
          <!--<div class="section-label">-->
            <!--<DefaultCheckbox-->
                <!--:label="'Enable SkladUSA insurance'"-->
                <!--v-model="checkox01"-->
            <!--/>-->
          <!--</div>-->

          <div class="custom-row">
            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_InsuranceForOrders'])"></div>
              <InputSum
                  :label="$t('siteSetting_InsuranceForOrders.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="general.data.insuranceCost"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_orderFeePerItemOverThree'])"></div>
              <InputSum
                  :label="$t('siteSetting_orderFeePerItemOverThree.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="general.data.orderFeePerItemOverThree"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_euConsolidationVat'])"></div>
              <InputSum
                  :label="$t('siteSetting_euConsolidationVat.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="general.data.euConsolidationVat"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_productConsolidationVat'])"></div>
              <InputSum
                  :label="$t('siteSetting_productConsolidationVat.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="general.data.productConsolidationVat"
              />
            </div>

            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_irelandExpressVat'])"></div>
              <InputSum
                  :label="$t('siteSetting_irelandExpressVat.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="general.data.irelandExpressVat"
              />
            </div>

<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['siteSetting_oneHourEngravingCost'])"></div>-->
<!--              <InputSum-->
<!--                  :label="$t('siteSetting_oneHourEngravingCost.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  :icoType="'dollar'"-->
<!--                  v-model="general.data.oneHourEngravingCost"-->
<!--              />-->
<!--            </div>-->
          </div>

        </div>
      </div>


    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  // import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "GeneralSystemPreferences",
    components: {
      AccordBlock,
      InputSum,
      // DefaultSelect,
      // DefaultCheckbox,
    },

    mixins: [mixinDetictingMobile],

    props: {
      general: Object,
    },

    data() {
      return {
        options: [{}],

        checkox01: true,

        input01: '',
        input02: '',
        input03: '',
        input04: '',
        input05: '',
        input06: '',
        input07: '',
        input08: '',
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
