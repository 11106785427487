<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <span
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['common_AreYouSure'])"></span>
        {{ $t('common_AreYouSure.localization_value.value') }}
      </span>
    </template>
    <template slot="body">

    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate([textConfirmDeletePopup.yes, textConfirmDeletePopup.no])"></span>

        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('confirm')"
        >
          {{$t('common_yes.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_no.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";

export default {
  name: "BlockCompletely",
  components: {
    Modal,
    MainButton,
  },

}

</script>

<style lang="scss">

</style>
