import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {
  OUTPUT_COMMISSION_1000_SETTING,
  OUTPUT_COMMISSION_3000_SETTING,
  OUTPUT_COMMISSION_500_SETTING,
  OUTPUT_KIT_GROUP_MINIMUM_LIMIT_SETTING,
  OUTPUT_UA_CARD_DAY_LIMIT_SETTING,
  OUTPUT_UA_CARD_MONTH_LIMIT_SETTING,
  OUTPUT_UA_CARD_WEEK_LIMIT_SETTING,
} from "../../../../../staticData/staticVariables";
import _ from 'lodash'

export function Limits() {
  this.checkValid = checkValid

  this.data = {
    outputKitGroupMinimumLimit: '',
    outputUaCardDayLimit: '',
    outputUaCardWeekLimit: '',
    outputUaCardMonthLimit: '',

    outputCommission500: '',
    outputCommission1000: '',
    outputCommission3000: '',

  }

  this.validation = {

  }

  this.validationTranslate = {

  }

  this.validationTxt = {

  }

}

/*****************   SETTERS   ******************/
Limits.prototype.setOutputKitGroupMinimumLimit = function (val) {
  this.data.outputKitGroupMinimumLimit = val;
}

Limits.prototype.setOutputUaCardDayLimit = function (val) {
  this.data.outputUaCardDayLimit = val;
}

Limits.prototype.setOutputUaCardWeekLimit = function (val) {
  this.data.outputUaCardWeekLimit = val;
}

Limits.prototype.setOutputUaCardMonthLimit = function (val) {
  this.data.outputUaCardMonthLimit = val;
}

Limits.prototype.setOutputCommission500 = function (val) {
  this.data.outputCommission500 = val;
}

Limits.prototype.setOutputCommission1000 = function (val) {
  this.data.outputCommission1000 = val;
}

Limits.prototype.setOutputCommission3000 = function (val) {
  this.data.outputCommission3000 = val;
}

Limits.prototype.setLimitsItem = function (val) {
  this.setOutputKitGroupMinimumLimit(parseFloat(_.find(val, {name: OUTPUT_KIT_GROUP_MINIMUM_LIMIT_SETTING}).value).toFixed(2))
  this.setOutputUaCardDayLimit(parseFloat(_.find(val, {name: OUTPUT_UA_CARD_DAY_LIMIT_SETTING}).value).toFixed(2))
  this.setOutputUaCardWeekLimit(parseFloat(_.find(val, {name: OUTPUT_UA_CARD_WEEK_LIMIT_SETTING}).value).toFixed(2))
  this.setOutputUaCardMonthLimit(parseFloat(_.find(val, {name: OUTPUT_UA_CARD_MONTH_LIMIT_SETTING}).value).toFixed(2))

  this.setOutputCommission500(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_500_SETTING}).value).toFixed(2))
  this.setOutputCommission1000(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_1000_SETTING}).value).toFixed(2))
  this.setOutputCommission3000(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_3000_SETTING}).value).toFixed(2))


}




/*****************   GETTERS   ******************/
Limits.prototype.getOutputKitGroupMinimumLimit = function (){
  return this.data.outputKitGroupMinimumLimit;
}

Limits.prototype.getOutputUaCardDayLimit = function (){
  return this.data.outputUaCardDayLimit;
}

Limits.prototype.getOutputUaCardWeekLimit = function (){
  return this.data.outputUaCardWeekLimit;
}

Limits.prototype.getOutputUaCardMonthLimit = function (){
  return this.data.outputUaCardMonthLimit;
}

Limits.prototype.getOutputCommission500 = function (){
  return this.data.outputCommission500;
}

Limits.prototype.getOutputCommission1000 = function (){
  return this.data.outputCommission1000;
}

Limits.prototype.getOutputCommission3000 = function (){
  return this.data.outputCommission3000;
}

/******************  PREPARE DATA  *****************/

Limits.prototype.prepareData = function () {

  return {
    "outputKitGroupMinimumLimit": this.getOutputKitGroupMinimumLimit(),
    "outputUaCardDayLimit": this.getOutputUaCardDayLimit(),
    "outputUaCardWeekLimit": this.getOutputUaCardWeekLimit(),
    "outputUaCardMonthLimit": this.getOutputUaCardMonthLimit(),

    "outputCommission500": this.getOutputCommission500(),
    "outputCommission1000": this.getOutputCommission1000(),
    "outputCommission3000": this.getOutputCommission3000(),
  }
}
