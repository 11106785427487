import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import _ from "lodash";
import {
  USER_NEGATIVE_BALANCE_BLOCK_MAX_DAYS,
  USER_NEGATIVE_BALANCE_BLOCK_MIN_LIMIT,
  USER_NEGATIVE_BALANCE_MAX_DAYS,
  USER_NEGATIVE_BALANCE_MIN_LIMIT
} from "../../../../../staticData/staticVariables";

export function Balance() {
  this.checkValid = checkValid

  this.data = {
    negativeBalance: '0',
    negativeBalanceDays: 0,
    negativeBalanceBlock: '0',
    negativeBalanceBlockDays: 0,
    positiveBalance: '0',
    positiveBalanceDays: '0',
  }

  this.validation = {
    negativeBalance: false,
    negativeBalanceDays: false,
    negativeBalanceBlock: false,
    negativeBalanceBlockDays: false,
    positiveBalance: false,
    positiveBalanceDays: false,
  }

  this.validationTranslate = {
    negativeBalance: '',
    negativeBalanceDays: '',
    negativeBalanceBlock: '',
    negativeBalanceBlockDays: '',
    positiveBalance: '',
    positiveBalanceDays: '',
  }

  /**
   * Getters
   */
  this.getNegativeBalance = () => {
    return this.data.negativeBalance
  }
  this.getNegativeBalanceDays = () => {
    return this.data.negativeBalanceDays
  }
  this.getNegativeBalanceBlock = () => {
    return this.data.negativeBalanceBlock
  }
  this.getNegativeBalanceBlockDays = () => {
    return this.data.negativeBalanceBlockDays
  }
  this.getPositiveBalance = () => {
    return this.data.positiveBalance
  }
  this.getPositiveBalanceDays = () => {
    return this.data.positiveBalanceDays
  }

  /**
   * Setters
   */
  this.setNegativeBalance = (val) => {
    this.data.negativeBalance = val
  }
  this.setNegativeBalanceDays = (val) => {
    this.data.negativeBalanceDays = val
  }
  this.setNegativeBalanceBlock = (val) => {
    this.data.negativeBalanceBlock = val
  }
  this.setNegativeBalanceBlockDays = (val) => {
    this.data.negativeBalanceBlockDays = val
  }
  this.setPositiveBalance = (val) => {
    this.data.positiveBalance = val
  }
  this.setPositiveBalanceDays = (val) => {
    this.data.positiveBalanceDays = val
  }

}




/******************  Setter DATA  *****************/
Balance.prototype.setBalanceItem = function (val) {
  this.setNegativeBalance(parseFloat(_.find(val, {name: USER_NEGATIVE_BALANCE_MIN_LIMIT}).value).toFixed(2))
  this.setNegativeBalanceDays((_.find(val, {name: USER_NEGATIVE_BALANCE_MAX_DAYS}).value))
  this.setNegativeBalanceBlock(parseFloat(_.find(val, {name: USER_NEGATIVE_BALANCE_BLOCK_MIN_LIMIT}).value).toFixed(2))
  this.setNegativeBalanceBlockDays((_.find(val, {name: USER_NEGATIVE_BALANCE_BLOCK_MAX_DAYS}).value))
  // this.setPositiveBalance(val)
  // this.setPositiveBalanceDays(val)
}


/******************  PREPARE DATA  *****************/

Balance.prototype.prepareData = function () {

  return {
    "negativeBalance": this.getNegativeBalance(),
    "negativeBalanceDays": this.getNegativeBalanceDays(),
    "negativeBalanceBlock": this.getNegativeBalanceBlock(),
    "negativeBalanceBlockDays": this.getNegativeBalanceBlockDays(),
    // "outputUaCardWeekLimit": this.getPositiveBalance(),
    // "outputUaCardMonthLimit": this.getPositiveBalanceDays(),
  }
}
