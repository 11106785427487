var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccordBlock',[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['siteSetting_balanceUnderZero',])}}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_balanceUnderZero.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-row pt-3 pb-2"},[_c('div',{staticClass:"custom-col mb-0"},[_c('div',{staticClass:"balance-under-row",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'siteSetting_turnOnNotificationsNBOne',
              'siteSetting_turnOnNotificationsNBTwo',
              'siteSetting_days',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_turnOnNotificationsNBOne.localization_value.value'))+" "),_c('InputSum',{staticClass:"balance-under-row__input",attrs:{"icoType":'dollar'},model:{value:(_vm.balance.data.negativeBalance),callback:function ($$v) {_vm.$set(_vm.balance.data, "negativeBalance", $$v)},expression:"balance.data.negativeBalance"}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_turnOnNotificationsNBTwo.localization_value.value'))+" "),_c('DefaultInput',{staticClass:"center balance-under-row__input",model:{value:(_vm.balance.data.negativeBalanceDays),callback:function ($$v) {_vm.$set(_vm.balance.data, "negativeBalanceDays", $$v)},expression:"balance.data.negativeBalanceDays"}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_days.localization_value.value'))+" ")],1)]),_c('div',{staticClass:"custom-col mb-0"},[_c('div',{staticClass:"balance-under-row",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'siteSetting_turnOffFuncNBOne',
              'siteSetting_turnOffFuncNBTwo',
              'siteSetting_days',
              ])}}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_turnOffFuncNBOne.localization_value.value'))+" "),_c('InputSum',{staticClass:"balance-under-row__input",attrs:{"icoType":'dollar'},model:{value:(_vm.balance.data.negativeBalanceBlock),callback:function ($$v) {_vm.$set(_vm.balance.data, "negativeBalanceBlock", $$v)},expression:"balance.data.negativeBalanceBlock"}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_turnOffFuncNBTwo.localization_value.value'))+" "),_c('DefaultInput',{staticClass:"center balance-under-row__input",model:{value:(_vm.balance.data.negativeBalanceBlockDays),callback:function ($$v) {_vm.$set(_vm.balance.data, "negativeBalanceBlockDays", $$v)},expression:"balance.data.negativeBalanceBlockDays"}}),_vm._v(" "+_vm._s(_vm.$t('siteSetting_days.localization_value.value'))+" ")],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }