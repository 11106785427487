import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import _ from "lodash";
import {
  ENGRAVING_ADJUSTING_THE_LAYOUT, ENGRAVING_DISCOUNT,
  ENGRAVING_TARIFF, ENGRAVING_TARIFF_DIFFERENT,
  ENGRAVING_TARIFF_PER_AREA, ENGRAVING_TARIFF_PER_ORDER,
  ONE_HOUR_ENGRAVING_COST,
} from "../../../../../staticData/staticVariables";

export function Engraving() {
  this.checkValid = checkValid

  this.data = {
    oneHourEngravingCost: '0',
    engravingTariffPerArea: '0',
    engravingTariff: '0',
    engravingTariffDifferent: '0',
    engravingAdjustingTheLayout: '0',
    engravingTariffPerOrder: '0',
    engravingDiscount: '0',
  }

  this.validation = {
    oneHourEngravingCost: false,
    engravingTariffPerArea: false,
    engravingTariff: false,
    engravingTariffDifferent: false,
    engravingAdjustingTheLayout: false,
    engravingTariffPerOrder: false,
    engravingDiscount: false,
  }

  this.validationTranslate = {
    oneHourEngravingCost: '',
    engravingTariffPerArea: '',
    engravingTariff: '',
    engravingTariffDifferent: '',
    engravingAdjustingTheLayout: '',
    engravingTariffPerOrder: '',
    engravingDiscount: '',
  }

  /**
   * Getters
   */
  this.getOneHourEngravingCost = () => {
    return this.data.oneHourEngravingCost
  }
  this.getEngravingTariffPerArea = () => {
    return this.data.engravingTariffPerArea
  }
  this.getEngravingTariff = () => {
    return this.data.engravingTariff
  }
  this.getEngravingTariffDifferent = () => {
    return this.data.engravingTariffDifferent
  }
  this.getEngravingAdjustingTheLayout = () => {
    return this.data.engravingAdjustingTheLayout
  }
  this.getEngravingTariffPerOrder = () => {
    return this.data.engravingTariffPerOrder
  }
  this.getEngravingDiscount = () => {
    return this.data.engravingDiscount
  }


  /**
   * Setters
   */
  this.setOneHourEngravingCost = (val) => {
    this.data.oneHourEngravingCost = val
  }
  this.setEngravingTariffPerArea = (val) => {
    this.data.engravingTariffPerArea = val
  }
  this.setEngravingTariff = (val) => {
    this.data.engravingTariff = val
  }
  this.setEngravingTariffDifferent = (val) => {
    this.data.engravingTariffDifferent = val
  }
  this.setEngravingAdjustingTheLayout = (val) => {
    this.data.engravingAdjustingTheLayout = val
  }
  this.setEngravingTariffPerOrder = (val) => {
    this.data.engravingTariffPerOrder = val
  }
  this.setEngravingDiscount = (val) => {
    this.data.engravingDiscount = val
  }
}




/******************  Setter DATA  *****************/
Engraving.prototype.setEngravingItem = function (val) {
  this.setOneHourEngravingCost(parseFloat(_.find(val, {name: ONE_HOUR_ENGRAVING_COST})?.value).toFixed(2))
  this.setEngravingTariffPerArea(parseFloat(_.find(val, {name: ENGRAVING_TARIFF_PER_AREA})?.value))
  this.setEngravingTariff(parseFloat(_.find(val, {name: ENGRAVING_TARIFF})?.value))
  this.setEngravingTariffDifferent(parseFloat(_.find(val, {name: ENGRAVING_TARIFF_DIFFERENT})?.value))
  this.setEngravingAdjustingTheLayout(parseFloat(_.find(val, {name: ENGRAVING_ADJUSTING_THE_LAYOUT})?.value))
  this.setEngravingTariffPerOrder(parseFloat(_.find(val, {name: ENGRAVING_TARIFF_PER_ORDER})?.value))
  this.setEngravingDiscount(parseFloat(_.find(val, {name: ENGRAVING_DISCOUNT})?.value))
}


/******************  PREPARE DATA  *****************/

Engraving.prototype.prepareData = function () {

  return {
    "oneHourEngravingCost": this.getOneHourEngravingCost(),
    "engravingTariffPerArea": this.getEngravingTariffPerArea(),
    "engravingTariff": this.getEngravingTariff(),
    "engravingTariffDifferent": this.getEngravingTariffDifferent(),
    "engravingAdjustingTheLayout": this.getEngravingAdjustingTheLayout(),
    "engravingTariffPerOrder": this.getEngravingTariffPerOrder(),
    "engravingDiscount": this.getEngravingDiscount(),
  }
}
