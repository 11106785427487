<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section">
          <GeneralSystemPreferences
                  :general="ms.data.General"
          />
        </div>
        <div class="detail-cards__section">
          <Engraving
                  :engraving="ms.data.Engraving"
          />
        </div>
        <div class="detail-cards__section">
          <ShippingCompanies
                  :shippingCompanies="ms.data.ShippingCompanies"
          />
        </div>
        <div class="detail-cards__section">
          <Limits
                  :limits="ms.data.Limits"
          />
        </div>
        <div class="detail-cards__section">
          <BalanceUnder
                  :balance="ms.data.Balance"
          />
        </div>
        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
            <router-link class="site-link site-link--alt brown" :to="$store.getters.GET_PATHS.dashboard">
              {{$t('common_cancel.localization_value.value')}}
            </router-link>
          </div>
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_update'])"></div>
            <MainButton
                class="sharp"
                :value="$t('common_update.localization_value.value')"
                @click.native="$emit('changeShippingCompaniesSettings')"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <CardLeftBlock
              :blockForAdmin="true"
              :backgroundImage="'product-admin'"
              class="pt-0"
          >
            <template slot="body">

              <UserInfo class="mb-4"/>

              <div class="site-link brown text-decoration-underline ">
                <div v-if="isAdmin && ms.data.historyOfChanged"
                     class="mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
                  <div class="site-link text-decoration-underline btn-style brown"
                       @click="historyOfChangesFunc('mainSettings', ms.data.historyOfChanged)">
                    {{$t('common_CheckHistory.localization_value.value')}}
                  </div>
                </div>
              </div>
            </template>
          </CardLeftBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import GeneralSystemPreferences from "./components/GeneralSystemPreferences/GeneralSystemPreferences";
  import ShippingCompanies from "./components/ShippingCompanies/ShippingCompanies";
  import BalanceUnder from "./components/BalanceUnder/BalanceUnder";
  import Limits from "./components/Limits/Limits";
  import Engraving
    from "@/components/modules/MainSettingsModule/components/MainSettingsEdit/MainSettingsEditAdmin/components/Engraving/Engraving";

  export default {
    name: "MainSettingsEditAdmin",

    components: {
      Limits,
      UserInfo,
      CardLeftBlock,
      MainButton,
      GeneralSystemPreferences,
      ShippingCompanies,
      BalanceUnder,
      Engraving,
    },

    props: {
      ms: Object,
    },

  }
</script>

<style scoped>

</style>
